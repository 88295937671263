import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  Text,
  Icon,
  useTheme,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Box,
  useClipboard,
} from "@chakra-ui/react";

import { MdOutlineModeEditOutline } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";
import { FaRegTrashAlt } from "react-icons/fa";
import { hexToRgba } from "utils/helpers";
import { LuClipboard } from "react-icons/lu";
import { IoCheckmarkOutline } from "react-icons/io5";
import { useQueryClient } from "@tanstack/react-query";

interface ChatItemProps {
  id: string;
  title: string;
  isSelected: boolean;
  isDisabled: boolean;
  setSelectedSession: (id: string | null) => void;
  onDeleteSession: () => void;
  onUpdateChatTitle: () => void;
}

export default function ChatItem({
  id,
  title,
  isSelected,
  isDisabled,
  setSelectedSession,
  onDeleteSession,
  onUpdateChatTitle,
}: ChatItemProps) {
  // Hooks
  const navigate = useNavigate();
  const { hasCopied, onCopy } = useClipboard(title);
  const queryClient = useQueryClient();

  // State
  const [showPopoverOptions, setShowPopoverOptions] = useState<boolean>(false);

  // Theme
  const { colors } = useTheme();
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";
  const bgColor = isLight ? "neutral.200" : "lightBackground";

  const handleNavigateToChat = (chatTitle: string) => {
    queryClient.setQueryData(['sessionTitle'], chatTitle);
    navigate("chat/" + id)
  }

  useEffect(() => {
    if (isSelected) {
      queryClient.setQueryData(['sessionTitle'], title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected]);

  return (
    <Flex
      my={"1px"}
      position={"relative"}
      align={"center"}
      justify={"space-between"}
      rounded={"xs"}
      cursor={"pointer"}
      fontWeight={isSelected ? "500" : "400"}
      letterSpacing={isSelected ? "-0.012rem" : "0"}
      pointerEvents={isDisabled ? "none" : "auto"}
      borderTopWidth={1}
      borderBottomWidth={1}
      bg={isSelected ? bgColor : "transparent"}
      borderColor={"transparent"}
      transition={"border .3s ease"}
      _hover={{ bg: bgColor }}
      onClick={() => handleNavigateToChat(title)}
      onMouseOver={() => setShowPopoverOptions(true)}
      onMouseLeave={() => setShowPopoverOptions(false)}
      borderRadius={"6px"}
    >
      {/* chat header */}
      <Text
        fontSize={{ lg: "12px", xl: "13px" }}
        whiteSpace={"nowrap"}
        overflow={"hidden"}
        textOverflow={"ellipsis"}
        color={"gray.600"}
        lineHeight={"1.3"}
        p={1.5}
      >
        {title}
      </Text>

      {/* chat options */}
      {showPopoverOptions && (
        <Box mr={"10px"} pr={"10px"}
          onClick={(e) => e.stopPropagation()}
        >
          <Popover placement="bottom-end" isLazy>
            <PopoverTrigger>
              <Flex
                position="absolute"
                right={1}
                top={0}
                bottom={0}
                align={"center"}
                h={"100%"}
              >
                <Icon
                  as={BsThreeDots}
                  boxSize={5}
                  p={1}
                  m={0}
                  bg={"background"}
                  color={"gray.500"}
                  borderRadius={"50px"}
                  _hover={{ color: "highlight.primary" }}
                />
              </Flex>
            </PopoverTrigger>
            <PopoverContent
              w={"160px"}
              h={"fit-content"}
              _focus={{ boxShadow: "none" }}
              m={0}
              p={0}
              position={"absolute"}
              right={-5}
              top={-3}
              boxShadow={"md"}
              bg={"background"}
            >
              <PopoverBody m={0} py={3} px={1.5} h="fit-content" w={"100%"}>
                <Flex direction={"column"} gap={1} m={0} p={0}>
                  {/* copy chat title */}
                  <Flex
                    color={"gray.500"}
                    align={"center"}
                    gap={2}
                    p={1}
                    borderRadius={"6px"}
                    cursor={"pointer"}
                    pointerEvents={hasCopied ? "none" : "auto"}
                    _hover={{ bg: "lightBackground" }}
                    onClick={onCopy}
                  >
                    <Icon
                      as={hasCopied ? IoCheckmarkOutline : LuClipboard}
                      boxSize={4}
                    />
                    <Text fontSize="14px">
                      {hasCopied ? `Copied` : `Copy title`}
                    </Text>
                  </Flex>

                  {/* rename chat title */}
                  <Flex
                    color={"gray.500"}
                    align={"center"}
                    gap={2}
                    p={1}
                    borderRadius={"6px"}
                    cursor={"pointer"}
                    _hover={{ bg: "lightBackground" }}
                    onClick={() => {
                      setSelectedSession(id)
                      onUpdateChatTitle()
                    }}
                  >
                    <Icon as={MdOutlineModeEditOutline} boxSize={4} />
                    <Text fontSize="14px">Rename title</Text>
                  </Flex>

                  {/* delete chat item */}
                  <Flex
                    color={"red.500"}
                    align={"center"}
                    gap={2}
                    p={1}
                    borderRadius={"6px"}
                    cursor={"pointer"}
                    _hover={{ bg: hexToRgba(colors.red[500], 0.25) }}
                    pointerEvents={isDisabled ? "none" : "auto"}
                    onClick={() => {
                      setSelectedSession(id)
                      onDeleteSession()
                    }}
                  >
                    <Icon as={FaRegTrashAlt} boxSize={"14px"} />
                    <Text fontSize="14px">Delete chat</Text>
                  </Flex>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      )
      }
    </Flex >
  );
}
