import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { selectCurrentEmbeddingData } from "redux/features/embedding/embeddingSlice";
import {
  Box,
  Flex,
  Icon,
  Text,
  Tooltip,
  useClipboard,
  useToast,
} from "@chakra-ui/react";

import { useDataLibraryAPI } from "api/useDataLibraryAPI";
import { useLiteraturesAPI } from "api/useLiteraturesAPI";
import useEmbeddingProcess from "hooks/literatureEmbedding/useEmbeddingProcess";

import Loading from "components/ui/Loading";
import { CoreContext } from "views/library/core";
import DeleteModal from "components/ui/DeleteModal";
import Doi from "components/papers/paperDetails/Doi";
import { ExternalId } from "models/papers/PaperProps";
import MainPanelError from "components/ui/MainPanelError";
import LensId from "components/papers/paperDetails/LensId";
import ActionButton from "components/buttons/ActionButton";
import Authors from "components/papers/paperDetails/Authors";
import Abstract from "components/papers/paperDetails/Abstract";
import Citation from "components/papers/paperDetails/Citation";
import FoldersModal from "components/library/bookmarks/modal/FoldersModal";
import TypeAndYear from "components/papers/paperDetails/TypeAndYear";

import { AxiosError } from "axios";
import { errorHandler } from "utils/helpers";
import { getPaperColor } from "components/ui/helpers";

import { FaCheck } from "react-icons/fa";
import { IoMdCode } from "react-icons/io";
import { BiSolidCopy } from "react-icons/bi";
import { BsBookmarkCheck, BsFileEarmarkX } from "react-icons/bs";
import { DeleteIcon } from "@chakra-ui/icons";

const LiteratureView = () => {
  // Hooks
  const toast = useToast();
  const queryClient = useQueryClient();
  const { fetchPapersById } = useLiteraturesAPI();
  const { setSourceItemName } = useContext(CoreContext);
  const { sourceItemId: id, layerName: layer } = useParams();

  // * Fetch paper details
  const { isLoading, error, data } = useQuery({
    queryKey: ["literature", id, layer],
    queryFn: fetchPapersById,
    staleTime: Infinity, // always fresh since it rarely changes
    gcTime: 30 * 1000 * 60, // unused cache is cleared after 30 mins
  });

  // States
  const [hasCopied, setHasCopied] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const { onCopy } = useClipboard(data?.title || "");
  const [showDeletePaperModal, setShowDeletePaperModal] = useState(false);
  const [selectedPaperId, setSelectedPaperId] = useState("");
  const [loading, setLoading] = useState(false);
  const { deleteProprietaryPaper } = useDataLibraryAPI();

  // Redux
  const { user } = useSelector(selectCurrentAuthData);
  const { progress } = useSelector(selectCurrentEmbeddingData);

  // Embedding
  const { initiateSocket } = useEmbeddingProcess();
  const isEmbeddingInProgress = progress !== null && progress < 100;

  // navigation
  const navigate = useNavigate();

  const initEmbedding = async (papers: string[] | string) => {
    // Embedding Only for premium
    if (user?.user_type !== "premium" || data?.embedded) return;

    // Don't allow multiple embeddings
    if (isEmbeddingInProgress) {
      toast({
        description: "An embed operation in progress, please wait",
        status: "error",
        position: "top-right",
      });
      return;
    }

    // Connect to a socket
    initiateSocket(papers);
  };
  // Handlers
  async function handleEmbedPaper() {
    if (data?.id) {
      await initEmbedding(data.id);
    }
  }

  function handleCopy() {
    onCopy();
    setHasCopied(true);
  }

  function handleDeleteClick(paperId: string) {
    setSelectedPaperId(paperId);
    setShowDeletePaperModal(true);
  }

  // Function called when the user confirms the deletion
  async function handleConfirmDeletePaper() {
    setLoading(true);
    setSelectedPaperId("");
    try {
      await deleteProprietaryPaper(selectedPaperId);
      toast({
        description: "Paper deleted successfully",
        status: "success",
        position: "top-right",
      });

      queryClient.invalidateQueries({ queryKey: ["PROPRIETARY-table"] });
      handleCloseDeletePaperModal();

      navigate(`/data/core/Private/data/literature`);
    } catch (error) {
      //
    } finally {
      setLoading(false);
      handleCloseDeletePaperModal();
    }
  }

  function handleCloseDeletePaperModal() {
    setShowDeletePaperModal(false);
  }

  const doi = data?.external_ids?.find(
    (id: ExternalId) => id?.type?.toLowerCase() === "doi"
  )?.value;

  useEffect(() => {
    if (data) {
      setSourceItemName(data?.title);
    }
    setHasCopied(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // TODO: (Amin): This approach should be applied consistently across all other views
  if (error) {
    const errorStatus = (error as AxiosError)?.status;

    return (
      <Flex w={"100%"} h={"100%"} my={10}>
        {errorStatus === 404 ? (
          // throws this error when Literature does not exist
          <Flex
            h={"100%"}
            w={"100%"}
            align={"center"}
            justify={"center"}
            color={"gray.500"}
            direction={"column"}
            gap={6}
          >
            <Icon as={BsFileEarmarkX} boxSize={"40px"} />
            <Text fontSize={"14px"} lineHeight={"1.28"} fontWeight={"500"}>
              No Literature found
            </Text>
          </Flex>
        ) : (
          // another error
          <MainPanelError errorMessage={errorHandler(error)?.message} />
        )}
      </Flex>
    );
  }

  if (isLoading) {
    return (
      <Flex
        w={"100%"}
        h={"100%"}
        align={"center"}
        justify={"center"}
        color={"gray.500"}
        direction={"column"}
        my={10}
      >
        <Loading message={`Loading literature data...`} />
      </Flex>
    );
  }

  return (
    <Flex direction={"column"} justify={"space-between"} h={"100%"}>
      <Flex direction={"column"} gap={3}>
        {/* paper type & publication year  */}
        {data && <TypeAndYear data={data} />}

        {/* title */}
        <Tooltip
          label={data?.title}
          placement={"left"}
          hasArrow
          display={data?.title && data?.title.length < 121 ? "none" : "block"}
          bg={"gray.900"}
          color={"gray.100"}
          py={2}
          px={3}
          m={2}
          maxW={[null, null, null, "200px", "220px", "240px"]}
          borderRadius={"6px"}
          fontSize={"12px"}
          boxShadow={"none"}
          arrowSize={8}
        >
          <Text
            fontSize={[null, null, null, "12px", "14px", "16px"]}
            fontWeight={"500"}
            color={getPaperColor(data?.paper_type)}
            lineHeight={"1.35"}
            display={"inline"}
            mb={1.5}
          >
            {data?.title && data?.title.length < 121
              ? data?.title
              : data?.title?.slice(0, 120) + ".."}

            <Icon
              ml={2}
              display={"inline"}
              color={hasCopied ? "highlight.primary" : "gray.500"}
              boxSize={"14px"}
              onClick={handleCopy}
              cursor={"pointer"}
              as={hasCopied ? FaCheck : BiSolidCopy}
            />
          </Text>
        </Tooltip>

        <Abstract data={data?.abstract ?? ""} />
        <Authors data={data?.authors ?? []} />
        <Citation data={data?.scholarly_citations_count} />
        <LensId data={data?.lens_id ?? ""} />
        <Doi data={doi ?? ""} />
      </Flex>

      {/* action buttons */}
      <Flex
        w={"100%"}
        alignItems="center"
        borderTopColor={"gray.200"}
        borderTopWidth={1}
        mt={5}
        gap={2}
        py={1}
      >
        <ActionButton
          aria-label={"Save"}
          label={"Save"}
          icon={<BsBookmarkCheck />}
          onClick={() => setShowSaveModal(true)}
        />

        {layer === "Private" && (
          <ActionButton
            aria-label={"Delete"}
            label={"Delete"}
            icon={<DeleteIcon />}
            onClick={() => handleDeleteClick(data?.id)}
          />
        )}

        <DeleteModal
          isOpen={showDeletePaperModal}
          onClose={handleCloseDeletePaperModal}
          onConfirm={handleConfirmDeletePaper}
          header={"Delete Literature"}
        >
          {loading ? (
            <Loading message={"Deleting paper ..."} />
          ) : (
            <Box fontSize={"14px"} mt={4}>
              <Text>{"Are you sure you want to delete paper"}</Text>
              <Text fontWeight={"bold"}>{` ${data?.title}?`}</Text>
            </Box>
          )}
        </DeleteModal>

        {/* provide embed option for premium users only */}
        {!isEmbeddingInProgress &&
          user?.user_type === "premium" &&
          !data?.embedded && (
            <ActionButton
              aria-label={"Embed"}
              label={"Embed"}
              icon={<IoMdCode />}
              onClick={handleEmbedPaper}
              isDisabled={data?.embedded}
            />
          )}
      </Flex>

      {/* Modal */}
      {data?.id && (
        <FoldersModal
          isOpen={showSaveModal}
          onClose={() => setShowSaveModal(false)}
          payload={{
            saveElementPayload: {
              elementType: "LITERATURE",
              content: {
                elementId: data?.id,
              },
            },
            successMessage: `Literature is successfully saved.`,
          }}
        />
      )}
    </Flex>
  );
};

export default LiteratureView;
